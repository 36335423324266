.App {
  padding-bottom: 20rem;
  background: white;
}

@media (min-width: 768px) {
  .App {
    /* padding-top: 110px !important; */
    padding-bottom: 20rem;
    margin-bottom: 280px;
    background: white;
  }
}

.App__gradient {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) calc(100% - 200px));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.App__gradient--with-section-title {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 64%, rgba(255,255,255,1) calc(100% - 240px));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 768px) {
  .App__gradient {
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 70%, rgba(255,255,255,1) calc(100% - 200px));
  }

  .App__gradient--with-section-title {
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(255,255,255,1) calc(100% - 240px));
  }
}


  