.Contact {
  margin-bottom: 8rem;
}

.Contact__info {
  padding: 2rem 0;
}

.Contact__links-list-item:not(:last-of-type) {
  margin-bottom: 0.5rem;
}