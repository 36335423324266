/* Styles */

.headline {
  font-size: 2.25rem;
  line-height: 1;
}

@media (min-width: 768px) {
  .headline {
    font-size: 4.5rem;
    line-height: 1;
  }
}

.title {
  font-size: 3rem;
  line-height: 1;
}

@media (min-width: 768px) {
  .title {
    font-size: 3.5rem;
    line-height: 1;
  }
}

.body {
  font-size: 2rem;
  line-height: 1;
}

@media (min-width: 768px) {
  .body {
    font-size: 2.5rem;
    line-height: 1;
    letter-spacing: 0.22px;
  }
}

.eyebrow {
  font-size: 1.685rem;
  text-transform: uppercase;
}

.small {
  font-size: 1.5rem;
}

/* Weights */

.regular {
  font-weight: 400;
}