.Capabilities {
  margin-bottom: 8rem;
}

.Capabilities--stuck {
  padding-top: 69px;
  z-index: 1;
}

.Capabilities--title {
  border-bottom: solid 1px black;
  max-width: 220px;
  margin-bottom: 2rem;
}

.Capabilities--section:not(:last-of-type) {
  margin-bottom: 2rem;
}

.Capabilities--section-item:not(:last-of-type) {
  margin-bottom: 0.25rem;
}