.Footer {
  color: black;
}

@media (min-width: 768px) {
  .Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: black;
    color: white;
    padding-bottom: 14rem;
  }
}

.Footer__links-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.Footer__links-list-item {
  cursor: pointer;
}

.Footer__signature {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .Footer__links-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    margin-bottom: 0;
  }

  .Footer__signature {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: 2rem;
  }
}