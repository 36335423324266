.Header {
  background: white;
  width: 100%;
}

.Header__item-container {
  background-color: white;
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  .Header__item-container {
    margin-bottom: 8rem;
  }
}

.Header h1 {
  background-color: white;
  font-weight: 500;
}