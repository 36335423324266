.Work {
  margin-bottom: 8rem;
}

.Work__projects-list-item:not(:last-of-type) {
  margin-bottom: 4rem;
}

.Work__projects-body {
  max-width: 890px;
}

.Work__projects-body > span {
  display: inline-block;
  margin-right: 0.5rem;
}

.Work__projects-more {
  max-width: 540px;
  margin-top: 2rem;
  line-height: 1.2;
}