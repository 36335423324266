.SmartImage {
  position: relative;
}

.SmartImage::after {
  display: block;
  content: '';
  padding: 32.22%;
}

.SmartImage__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  opacity: 0;
}

.SmartImage__image {
  opacity: 1;
}

.SmartImage__fallback {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  opacity: 0;
}

.loaded {
  opacity: 1;
}


