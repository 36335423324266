.WorkDetail {
}

.WorkDetail__image-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.WorkDetail__image-wrapper {
  width: 100%;
}

.WorkDetail__image {
  width: 100%;
}

.WorkDetail__link-container a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.WorkDetail__link-container span {
  color: black;
}

@media (min-width: 768px) {
  .WorkDetail__image-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    flex-direction: row;
  }

  .WorkDetail__image-wrapper {
    width: calc(100% / 3);
  }

  .WorkDetail__link-container a {
    flex-direction: row;
  }

  .WorkDetail__link-container span:first-of-type {
    margin-right: 1rem;
  }
}