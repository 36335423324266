/* Padding/Margin */
.wrapper-padding {
  padding: 1rem;
}

/* Widths */
.body-max-width {
  max-width: 1180px;
}

/* Display */
.block { display: block; }
.inline-block { display: inline-block; }
.inline { display: inline; }