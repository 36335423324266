.RevealLink a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.RevealLink span {
  color: black;
}

@media (min-width: 768px) {
  .RevealLink a {
    flex-direction: row;
  }

  .RevealLink span:first-of-type {
    margin-right: 1rem;
  }
}