@font-face {
  font-family: 'Beausite Grand';
  src: url('/fonts/BeausiteGrandWeb-Regular.eot');
  src: url('/fonts/BeausiteGrandWeb-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BeausiteGrandWeb-Regular.woff2') format('woff2'),
      url('/fonts/BeausiteGrandWeb-Regular.woff') format('woff'),
      url('/fonts/BeausiteGrandWeb-Regular.ttf') format('truetype'),
      url('/fonts/BeausiteGrandWeb-Regular.svg#BeausiteGrandTrial-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Beausite Grand', Georgia, 'Times New Roman', Times, serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import 'reset.css';
@import 'type.css';
@import 'layout.css';
