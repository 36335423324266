.Section {
  margin-bottom: 8rem;
}

.Section--title {
  border-bottom: solid 1px black;
  width: 240px;
  background: white;
}

.Section__body {
  margin-top: 2rem;
}

.Section__title--stuck {
  top: 232px
}

.Section .sticky-inner-wrapper {
  z-index: 1;
}